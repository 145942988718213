export function useEffectOnce(callback, when) {
  const hasRunOnceRef = React.useRef(false)

  React.useEffect(
    () => {
      if (when && !hasRunOnceRef.current) {
        callback()
        hasRunOnceRef.current = true
      }
    },
    [callback, when]
  )
}
