import { useWindowEventListener } from '/machinery/useWindowEventListener'
import { useSpringValue } from 'react-spring'
import { useEvent } from '/machinery/useEvent'

export function useCursor() {
  const elementRef = React.useRef(null)

  const x = useSpringValue(0, { immediate: true })
  const y = useSpringValue(0, { immediate: true })

  const width = useSpringValue(0)
  const height = useSpringValue(0)

  const isHovering = useSpringValue(false, {
    config: { duration: 350 },
    delay: 800,
  })

  const onResizeEvent = useEvent(handleResize)
  const onMoveEvent = useEvent(handleMouseMove)
  const onEnterEvent = useEvent(handleEnter)
  const onLeaveEvent = useEvent(handleLeave)

  useWindowEventListener('resize', onResizeEvent)
  useWindowEventListener('pointermove', onMoveEvent)

  const listeners = {
    onMouseEnter: onEnterEvent,
    onMouseLeave: onLeaveEvent,
  }

  return { ref: elementRef, isHovering, x, y, height, width, listeners }

  function handleMouseMove(e) {
    const { top } = elementRef?.current?.getBoundingClientRect()

    if (e) {
      x.set(e?.clientX)
      y.set(e?.clientY - top)
    }
  }

  function handleResize() {
    height.set(window.innerHeight)
    width.set(window.innerWidth)
  }

  function handleEnter() {
    isHovering.set(true)
  }

  function handleLeave() {
    isHovering.set(false)
  }
}
