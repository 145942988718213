import { animated, config, to, useSpring, useSpringValue } from 'react-spring'
import { HeadingSecondaryXxl } from '/components/buildingBlocks/Heading'
import { TextSecondaryMd } from '/components/buildingBlocks/Text'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'
import { useEffectOnce } from '/machinery/useEffectOnce'
import { easeOutQuart } from '/machinery/easings'
import { useKeenSlider } from 'keen-slider/react'
import { useCursor } from '/machinery/useCursor'
import { mergeRefs } from '/machinery/mergeRefs'
import { useEvent } from '/machinery/useEvent'

import visual from '/images/circle-8-outline-visual.raw.svg'
import iconChevronLeft from '/images/icons/chevron-left.svg'
import iconChevronRight from '/images/icons/chevron-right.svg'

import f1 from '/images/corporate/f1/f1.jpg'
import f1400w from '/images/corporate/f1/f1-400w.avif'
import f1600w from '/images/corporate/f1/f1-600w.avif'
import f1800w from '/images/corporate/f1/f1-800w.avif'
import f11000w from '/images/corporate/f1/f1-1000w.avif'
import f11200w from '/images/corporate/f1/f1-1200w.avif'
import f11600w from '/images/corporate/f1/f1-1600w.avif'

import zoomies from '/images/corporate/zoomies/zoomies.png'
import zoomies400w from '/images/corporate/zoomies/zoomies-400w.avif'
import zoomies600w from '/images/corporate/zoomies/zoomies-600w.avif'
import zoomies800w from '/images/corporate/zoomies/zoomies-800w.avif'
import zoomies1000w from '/images/corporate/zoomies/zoomies-1000w.avif'
import zoomies1200w from '/images/corporate/zoomies/zoomies-1200w.avif'
import zoomies1600w from '/images/corporate/zoomies/zoomies-1600w.avif'

import styles from './Slider.css'

export function Slider({ layoutClassName }) {
  const { ref: visibilityRef, isInViewport } = useIsInViewport()
  const { ref: sliderRef, isDragging } = useInstanciatedKeenSlider({ isInViewport })
  const { ref: cursorRef, isHovering, x, y, listeners } = useCursor()
  const { scale } = useSpring({ config: config.gentle, scale: getScale() })

  const refs = mergeRefs(visibilityRef, cursorRef)

  return (
    <div ref={refs} className={cx(styles.component, layoutClassName)} {...listeners}>
      <Cursor
        transform={getTransform()}
        layoutClassName={styles.cursorLayout}
        {...{ x, y, scale, isHovering, isDragging }}
      />

      <div ref={sliderRef} className={cx('keen-slider', styles.slider)}>
        {cardContent.map((content, i) => (
          <Card
            key={i}
            positions={cardBackgroundVisualPositions[content.id]}
            layoutClassName={styles.cardLayout}
            {...{ content }}
          />
        ))}
      </div>
    </div>
  )

  function getTransform() {
    return to(
      [scale, x, y],
      (transformScale, translateX, translateY) => (
        `translate(
          ${translateX - (transformScale / 2)}px,
          ${translateY - (transformScale / 2)}px
        )`
      )
    )
  }

  function getScale(base = 60) {
    return to(
      [isHovering, isDragging],
      (hovering, dragging) => hovering
        ? (dragging ? base * 0.75 : base)
        : base / 2
    )
  }
}

function Cursor({ scale, isHovering, isDragging, transform, layoutClassName }) {
  return (
    <animated.div
      className={cx(styles.componentCursor, layoutClassName)}
      style={{
        transform,
        '--color-mix-value': isDragging.to(x => `${x ? 80 : 100}%`),
        '--scale': scale.to(x => `${x}px`)
      }}
    >
      <animated.span
        className={styles.iconContainer}
        style={{ x: getPosition({ modifier: 1 }), opacity: getOpacity() }}
      >
        <Image src={iconChevronLeft} layoutClassName={styles.iconLayout} />
      </animated.span>

      <animated.span
        className={styles.iconContainer}
        style={{ x: getPosition({ modifier: -1 }), opacity: getOpacity() }}
      >
        <Image src={iconChevronRight} layoutClassName={styles.iconLayout} />
      </animated.span>
    </animated.div>
  )

  function getPosition({ modifier }) {
    return to(
      [isHovering, isDragging],
      (hovering, dragging) => hovering
        ? (dragging ? (3 * modifier) : 0)
        : 10 * modifier
    )
  }

  function getOpacity() {
    return isHovering.to(x => x ? 1 : 0)
  }
}

function Image({ src, layoutClassName = undefined }) {
  return (
    <img
      alt=''
      role='presentation'
      className={layoutClassName}
      {...{ src }}
    />
  )
}

function Card({ content, positions, layoutClassName }) {
  return (
    <div className={cx('keen-slider__slide', styles.componentCard, layoutClassName)}>
      <HeadingSecondaryXxl h='strong' heading={content.year} layoutClassName={styles.headingLayout} />
      <TextSecondaryMd layoutClassName={styles.textLayout}>{content.text}</TextSecondaryMd>

      {content.background === 'logo' && (
        <BackgroundVisual layoutClassName={styles.backgroundVisualLayout} {...{ positions }} />
      )}

      {content.background === 'rebrand' && (
        <ZoomiesBackground layoutClassName={styles.backgroundImageLayout} />
      )}

      {content.background === 'F1' && (
        <F1Background layoutClassName={styles.backgroundImageLayout} />
      )}
    </div>
  )
}

function ZoomiesBackground({ layoutClassName }) {
  const sizes = {
    400: zoomies400w,
    600: zoomies600w,
    800: zoomies800w,
    1000: zoomies1000w,
    1200: zoomies1200w,
    1600: zoomies1600w
  }

  return (
    <BackgroundImage src={zoomies} {...{ sizes, layoutClassName }} />
  )
}

function F1Background({ layoutClassName }) {
  const sizes = {
    400: f1400w,
    600: f1600w,
    800: f1800w,
    1000: f11000w,
    1200: f11200w,
    1600: f11600w
  }

  return (
    <BackgroundImage src={f1} {...{ sizes, layoutClassName }} />
  )
}

function BackgroundImage({ layoutClassName, sizes, src }) {
  return (
    <img
      alt=''
      role='presentation'
      className={cx(styles.componentBackgroundImage, layoutClassName)}
      srcSet={Object.entries(sizes).map(([k, v]) => `${v} ${k}w`).join(', ')}
      sizes={Object.keys(sizes).map(x => `(max-width: ${x}px) ${x}px`).join(', ')}
      {...{ src }}
    />
  )
}

function BackgroundVisual({ positions, layoutClassName }) {
  return (
    <span
      className={cx(styles.componentBackgroundVisual, layoutClassName)}
      style={{ '--position-top': positions.top, '--position-left': positions.left }}
      dangerouslySetInnerHTML={{ __html: visual }}
    />
  )
}

function useInstanciatedKeenSlider({ isInViewport }) {
  const isDragging = useSpringValue(false, {
    config: { duration: 350 },
    delay: 800,
  })

  const [sliderRef, instanceRef] = useKeenSlider({
    renderMode: 'performance',
    loop: false,
    mode: 'snap',
    slides: {
      origin: 'center',
      perView: 'auto',
      spacing: 25,
    },
  })

  const onHandleEvent = useEvent(handleEvent)
  const onPlayHintEvent = useEvent(handlePlaySlideHint)

  React.useEffect(
    () => {
      instanceRef.current.update()

      onHandleEvent('dragStarted', () => {
        isDragging.start(true)
      })

      onHandleEvent('dragEnded', () => {
        isDragging.start(false)
      })
    },
    [onHandleEvent, isInViewport, isDragging, instanceRef]
  )

  useEffectOnce(
    () => onPlayHintEvent(),
    isInViewport
  )

  return { ref: sliderRef, isDragging }

  function handleEvent(event, callback) {
    instanceRef?.current?.on(event, callback)
  }

  function handlePlaySlideHint() {
    instanceRef.current.animator.start([
      { distance: 0.3, duration: 1000, easing: easeOutQuart }
    ])
  }
}

const cardContent = [
  { id: 1, year: 2021, text: 'Axiom Partners (Switzerland) acquires DSG, including KwiiK and People for Office, leading to the foundation of Circle8.', background: 'logo' },
  { id: 2, year: 2022, text: 'DSG rebranded as Circle8; acquisitions of Seven Stars and FixedToday (NL).', background: 'rebrand' },
  { id: 4, year: 2023, text: 'Acquisition of Königstein (Germany) and Swisslinx (Switzerland); Ineke Kooistra appointed as CEO of Circle8.', background: 'logo' },
  { id: 3, year: 2023, text: 'Circle8 breaks through one billion euro mark', background: 'logo' },
  { id: 5, year: 2024, text: 'Circle8 becomes Global Partner of the Aston Martin F1® team', background: 'F1' }
]

const cardBackgroundVisualPositions = {
  1: { top: '-175%', left: '25%' },
  3: { top: '-100%', left: '30%' },
  4: { top: '-50%', left: '10%' },
}
